.futMinboxhead {margin: 46px 0;text-align: center;}
.funtLowmianbox {display: flex;width: 100%;}
.funMinimgbox{width: 100%;}
.funLftloerbox {position: relative;width: 40%;}
.funRgtlowemainbox {width: 62%;background: #f8f8f8;border-left: 1px solid  #7f279f;}
.funtMiantextbox{z-index: 1;position: absolute;top: calc(50% - 125px);left: 30px;width: 485px;}
.funmboRepoimbox{display: none;}
.funMinimgbox img{width: 100%;vertical-align: middle;}
@media screen and (max-width: 1024px){
	.futMinboxhead{margin: 26px 0; } 
	.funtMiantextbox .stepFacthed{margin-bottom: 16px;font-size: 24px;}
	.funtMiantextbox {width: 340px;}
	.funtMiantextbox {left: 24px;top: calc(50% - 110px);}
}
@media screen and (max-width: 768px){
	
    .funtMiantextbox .stepFacthed {font-size: 20px;margin-bottom: 5px;}
	.funtMiantextbox p{font-size: 26px;line-height: 30px;}
     .funtMiantextbox {width: 235px;}
}
@media screen and (max-width: 540px){
	.funmboRepoimbox {display: block;}
	.funMinimgbox {display: none;}
	.funtLowmianbox{flex-direction: column-reverse;}
	.funLftloerbox{width: 100%;}
	.funRgtlowemainbox{width: 100%;height: 222px;}
	.funmboRepoimbox img{width: 100%;}
	.funtMiantextbox{width: 100%;}
	.funtMiantextbox .stepFacthed{font-size: 20px;margin-bottom: 0px;}
	.funtMiantextbox p{font-size: 26px;line-height: 30px;}
	.funtMiantextbox{padding: 16px; left: 0px;top: 0px;}
	.futMinboxhead{margin-bottom: 0px;background-image: linear-gradient(258deg, #7f279f 100%, #1c2f59 0%);}
	.futMinboxhead h3{padding: 16px; color: #fff; font-size: 20px;text-align: left;line-height: 24px;}
	.funRgtlowemainbox{border-left: none;}
   }